import { useNavigate } from "react-router-dom";
import Logotipo from "./logotipo/Logotipo";
import Menu from "./menu/Menu";
import styles from "./MenuLateral.module.css";
import { useState } from "react";

const menus = [
  {
    icone: "add",
    nome: "Novo",
    url: "",
    submenu: [
      { icone: "trending_down", nome: "Despesa", url: "?lancamento=despesa" },
      { icone: "trending_up", nome: "Receita", url: "?lancamento=receita" },
      { icone: "swap_horiz", nome: "Transferência", url: "?lancamento=transferencia" }
    ]
  },
  { icone: "dashboard", nome: "Dashboard", url: "/dashboard", submenu: [] },
  { icone: "currency_exchange", nome: "Movimentações", url: "/movimentacoes", submenu: [] },
  { icone: "assured_workload", nome: "Contas", url: "/contas", submenu: [] },
  // { icone: "credit_card", nome: "Cartões de crédito", url: "/cartoes" },
  { icone: "category", nome: "Categorias", url: "/categorias", submenu: [] },
  { icone: "person", nome: "Perfil", url: "/perfil", submenu: [] }
  // { icone: "settings", nome: "Configurações", url: "/configuracoes", submenu: [] }
];

export default function MenuLateral() {
  const [menuExpandido, setMenuExpandido] = useState("");

  const navigate = useNavigate();

  function handleNavigate(nome: string, url: string) {
    if (url === "") {
      if (menuExpandido === nome) {
        setMenuExpandido("");
      } else {
        setMenuExpandido(nome);
      }
      return;
    }

    setMenuExpandido("");
    navigate(url);
  }

  function handleLogoff() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Logotipo />
        <div className={styles.lista}>
          {menus.map((menu, index) => (
            <Menu
              key={index}
              icone={menu.icone}
              nome={menu.nome}
              url={menu.url}
              submenu={menu.submenu}
              expandido={menuExpandido}
              handleNavigate={handleNavigate}
            />
          ))}
        </div>
        <div className={styles.sair}>
          <Menu icone="logout" nome="Sair" url="" submenu={[]} expandido="" handleNavigate={handleLogoff} />
        </div>
      </div>
    </div>
  );
}

