import { useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./RecuperarSenha.module.css";
import Botao from "../../../componentes/botao/Botao";
import { useNavigate } from "react-router-dom";
import Logotipo from "../../estruturais/menuLateral/logotipo/Logotipo";

export default function RecuperarSenha() {
  const [dados, setDados] = useState({ email: "" });

  const navigate = useNavigate();

  const handleRecuperarSenha = () => {
    alert("Em desenvolvimento");
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.painelEsquerdo}>
          <div className={styles.voltar} onClick={() => navigate("/login")}>
            <span className={"material-symbols-rounded"}>arrow_back</span>
            <span>VOLTAR</span>
          </div>
          <div className={styles.titulo}>Redefinir senha</div>
          <div className={styles.dica}>Digite o e-mail cadastrado para receber as instruções de redefinição de senha.</div>
          <div className={styles.formulario}>
            <TextField
              label="Digite o e-mail cadastrado"
              value={dados.email}
              onChange={(email) => setDados({ ...dados, email: email })}
              type="text"
              enabled={true}
            />
            <Botao texto="Continuar" onClick={() => handleRecuperarSenha()} background="#ffd862" color="#333333" />
          </div>
        </div>
        <div className={styles.painelDireito}>
          <Logotipo />
          <span>Seu organizador financeiro</span>
        </div>
      </div>
    </div>
  );
}

