import { useEffect, useState } from "react";
import TextField from "../../../componentes/textField/TextField";
import styles from "./Filtros.module.css";
import DropDown from "../../../componentes/dropdown/DropDown";
import { useContas } from "../../../servicos/contas/useContas";
import { useCategorias } from "../../../servicos/categorias/useCategorias";
import Botao from "../../../componentes/botao/Botao";

export type FiltrosType = {
  foi_transacionada?: boolean;
  tipo?: string;
  dataInicial?: Date;
  dataFinal?: Date;
  descricao?: string;
  valorInicial?: number;
  valorFinal?: number;
  conta?: string;
  categoria?: string;
  tipo_pagamento?: string;
};

const tipoPagamento = [
  { icone: "money", valor: "PIX", texto: "PIX" },
  { icone: "money", valor: "Dinheiro", texto: "Dinheiro" },
  { icone: "credit_card", valor: "Cartão de Crédito", texto: "Cartão de Crédito" },
  { icone: "credit_card", valor: "Cartão de Debito", texto: "Cartão de Debito" }
];

type FiltrosProps = {
  filtros: FiltrosType;
  setFiltros: (filtros: FiltrosType) => void;
  clearFiltros: () => void;
  onClose: () => void;
};

export default function Filtros(props: FiltrosProps) {
  const [filtros, setFiltros] = useState<FiltrosType>({});

  const { contas } = useContas();
  const { categorias } = useCategorias();

  const handleLimparFiltros = () => {
    setFiltros({
      foi_transacionada: undefined,
      tipo: "",
      dataInicial: undefined,
      dataFinal: undefined,
      descricao: "",
      valorInicial: undefined,
      valorFinal: undefined,
      conta: "",
      categoria: "",
      tipo_pagamento: ""
    });

    props.clearFiltros();
  };

  const handleFiltrar = () => {
    props.setFiltros({
      ...filtros
    });
    props.onClose();
  };

  useEffect(() => {
    setFiltros({
      ...props.filtros
    });
  }, [props.filtros]);

  const opcoesCategoria = categorias.map((categoria) => {
    return { icone: categoria.icone, valor: categoria.id?.toString() || "", texto: categoria.nome };
  });

  const opcoesConta = contas.map((conta) => {
    return { icone: "", valor: conta.id?.toString() || "", texto: conta.descricao };
  });

  return (
    <div>
      <div className={styles.filtros}>
        <div className={styles.filtro}>
          <span className={styles.filtroTitulo}>Filtrar por Descrição</span>
          <div className={styles.filtroLinha}>
            <TextField
              label="Descrição"
              type="text"
              value={filtros.descricao}
              onChange={(e) => setFiltros({ ...filtros, descricao: e })}
              enabled={true}
            />
          </div>
        </div>

        <div className={styles.filtro}>
          <span className={styles.filtroTitulo}>Período de Data</span>
          <div className={styles.filtroLinha}>
            <TextField
              label="Data inicial"
              type="date"
              value={filtros.dataInicial || ""}
              onChange={(data) => setFiltros({ ...filtros, dataInicial: data })}
              enabled={true}
            />
            <TextField
              label="Data final"
              type="date"
              value={filtros.dataFinal || ""}
              onChange={(data) => setFiltros({ ...filtros, dataFinal: data })}
              enabled={true}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <span className={styles.filtroTitulo}>Faixa de Valor</span>
          <div className={styles.filtroLinha}>
            <TextField
              label="Valor inicial"
              type="currency"
              value={filtros.valorInicial?.toString()}
              onChange={(valor) => setFiltros({ ...filtros, valorInicial: valor })}
              enabled={true}
            />
            <TextField
              label="Valor final"
              type="currency"
              value={filtros.valorFinal?.toString()}
              onChange={(valor) => setFiltros({ ...filtros, valorFinal: valor })}
              enabled={true}
            />
          </div>
        </div>
        <div className={styles.filtroLinha}>
          <div className={styles.filtro}>
            <span className={styles.filtroTitulo}>Tipo de Transação</span>
            <div className={styles.opcoes}>
              <div
                className={styles.opcao}
                style={{ backgroundColor: "#198000", color: "#FFFFFF", borderColor: filtros.tipo === "R" ? "#00adff" : "#FFFFFF" }}
                onClick={() => setFiltros({ ...filtros, tipo: "R" })}
              >
                <span>ENTRADA</span>
              </div>
              <div
                className={styles.opcao}
                style={{ backgroundColor: "#cc4400", color: "#FFFFFF", borderColor: filtros.tipo === "D" ? "#00adff" : "#FFFFFF" }}
                onClick={() => setFiltros({ ...filtros, tipo: "D" })}
              >
                <span>SAIDA</span>
              </div>
            </div>
          </div>
          <div className={styles.filtro}>
            <span className={styles.filtroTitulo}>Situação da Transação</span>
            <div className={styles.opcoes}>
              <div
                className={styles.opcao}
                style={{
                  backgroundColor: "#198000",
                  color: "#FFFFFF",
                  borderColor: filtros.foi_transacionada === true ? "#00adff" : "#FFFFFF"
                }}
                onClick={() => setFiltros({ ...filtros, foi_transacionada: true })}
              >
                <span>PAGA</span>
              </div>
              <div
                className={styles.opcao}
                style={{
                  backgroundColor: "#ed9528",
                  color: "#FFFFFF",
                  borderColor: filtros.foi_transacionada === false ? "#00adff" : "#FFFFFF"
                }}
                onClick={() => setFiltros({ ...filtros, foi_transacionada: false })}
              >
                <span>PENDENTE</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filtro}>
          <span className={styles.filtroTitulo}>Modo de Pagamento/Recebimento</span>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Selecione"
              valor={filtros.tipo_pagamento}
              onChange={(valor) => setFiltros({ ...filtros, tipo_pagamento: valor })}
              opcoes={tipoPagamento}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <span className={styles.filtroTitulo}>Conta do Lançamento</span>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Selecione"
              valor={filtros.conta}
              onChange={(valor) => setFiltros({ ...filtros, conta: valor })}
              opcoes={opcoesConta}
            />
          </div>
        </div>
        <div className={styles.filtro}>
          <span className={styles.filtroTitulo}>Categoria do Lançamento</span>
          <div className={styles.filtroLinha}>
            <DropDown
              label="Selecione"
              valor={filtros.categoria}
              onChange={(valor) => setFiltros({ ...filtros, categoria: valor })}
              opcoes={opcoesCategoria}
            />
          </div>
        </div>
      </div>
      <div className={styles.acoes}>
        <Botao texto="Filtrar" onClick={handleFiltrar} enabled={true} background="#ffd862" color="#333333" />
        <Botao texto="Limpar Filtros" onClick={handleLimparFiltros} enabled={true} background="#f5f5f5" color="#333333" />
      </div>
    </div>
  );
}

