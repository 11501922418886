import styles from "./Logotipo.module.css";

export default function Logotipo() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logotipo}>
          <span className={styles.infos}>
            <b>Finant</b>
          </span>
        </div>
      </div>
    </div>
  );
}

