export const cores = ["#03a9f4", "#4caf50", "#ffc107", "#ff9800", "#ff5722", "#cc0000", "#9e9e9e", "#607d8b"];
export const icones = [
  "home",
  "search",
  "menu",
  "favorite",
  "settings",
  "info",
  "help",
  "account_circle",
  "shopping_cart",
  "visibility",
  "edit",
  "delete",
  "check_circle",
  "close",
  "warning",
  "arrow_forward",
  "arrow_back",
  "add_circle",
  "remove_circle",
  "done",
  "lock",
  "camera",
  "send",
  "download",
  "upload",
  "folder",
  "cloud",
  "event",
  "schedule",
  "alarm",
  "call",
  "message",
  "place",
  "directions",
  "attach_file",
  "save",
  "open_in_new",
  "link",
  "share",
  "print",
  "map",
  "gps_fixed",
  "navigation",
  "wifi",
  "bluetooth",
  "battery_full",
  "flash_on",
  "brightness_high",
  "volume_up",
  "volume_off",
  "pause",
  "play_arrow",
  "stop",
  "skip_next",
  "skip_previous",
  "repeat",
  "shuffle",
  "mic",
  "headset",
  "music_note",
  "videocam",
  "movie",
  "gamepad",
  "smartphone",
  "tablet",
  "laptop",
  "desktop_windows",
  "tv",
  "watch",
  "developer_mode",
  "code",
  "bug_report",
  "security",
  "build",
  "power",
  "memory",
  "storage",
  "usb",
  "network_wifi",
  "signal_cellular_4_bar",
  "sync",
  "autorenew",
  "update",
  "settings_backup_restore",
  "data_usage",
  "account_balance",
  "attach_money",
  "credit_card",
  "euro",
  "payments",
  "monetization_on",
  "trending_up",
  "trending_down",
  "bar_chart",
  "pie_chart",
  "insights",
  "account_tree",
  "engineering",
  "leaderboard",
  "flag",
  "policy",
  "work",
  "contact_support",
  "privacy_tip",
  "visibility_off",
  "filter_list",
  "sort",
  "star",
  "grade",
  "verified",
  "new_releases",
  "whatshot",
  "trending_flat",
  "bolt",
  "rocket_launch",
  "electric_car",
  "public",
  "person",
  "group",
  "people",
  "groups",
  "pets",
  "child_friendly",
  "accessibility",
  "family_restroom",
  "pregnant_woman",
  "wc",
  "baby_changing_station",
  "reduce_capacity",
  "sick",
  "elderly",
  "handshake",
  "emoji_emotions",
  "emoji_objects",
  "emoji_nature",
  "sports_soccer",
  "sports_basketball",
  "sports_baseball",
  "sports_football",
  "directions_run",
  "fitness_center",
  "local_dining",
  "fastfood",
  "kitchen",
  "local_cafe",
  "restaurant_menu",
  "emoji_food_beverage",
  "wine_bar",
  "local_bar",
  "nightlife",
  "local_pizza",
  "bakery_dining",
  "outdoor_grill",
  "brunch_dining",
  "takeout_dining"
  // Adicione mais ícones conforme necessário...
];

