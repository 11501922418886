import styles from "./TransacoesMensais.module.css";
import { Bar } from "react-chartjs-2";
import { Chart, BarElement, Tooltip, Legend, CategoryScale, LinearScale } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importa o plugin de datalabels
import { useTransacoes } from "../../../servicos/transacoes/useTransacoes";
import { Transacao } from "../../../servicos/transacoes/typeTransacao";
import Quadro from "../Quadro/Quadro";

// Registrar os elementos necessários para o gráfico de barras, incluindo o plugin de datalabels
Chart.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale, ChartDataLabels);

type DashboardProps = {
  transacoes: Transacao[];
};

export default function TransacoesMensais({ transacoes }: DashboardProps) {
  const isMobile = window.innerWidth < 768;

  // Função para obter o mês e ano no formato MM/AAAA
  const obterMesAno = (data: Date) => {
    const mes = new Date(data).getMonth() + 1;
    const ano = new Date(data).getFullYear();
    return `${String(mes).padStart(2, "0")}/${ano}`;
  };

  // Função para gerar os últimos 12 meses incluindo o mês atual
  const gerarUltimosMeses = () => {
    const hoje = new Date();
    const meses = [];
    for (let i = 0; i < (isMobile ? 3 : 12); i++) {
      const data = new Date(hoje.getFullYear(), hoje.getMonth() - i, 1);
      meses.unshift(obterMesAno(data)); // Adiciona o mês no início do array
    }
    return meses;
  };

  // Agrupar transações por mês (receitas - despesas)
  const transacoesPorMes = transacoes.reduce((acc: any, transacao: Transacao) => {
    const mesAno = obterMesAno(transacao.data);
    const valor = parseFloat(transacao.valor.toString());

    // Inicializa o mês com 0 se ainda não foi adicionado
    if (!acc[mesAno]) acc[mesAno] = 0;

    // Adiciona o valor ao mês, somando receitas e subtraindo despesas
    acc[mesAno] += transacao.tipo === "R" ? valor : -valor;
    return acc;
  }, {});

  const meses = gerarUltimosMeses();
  const valores = meses.map((mes) => transacoesPorMes[mes] || 0); // Garantir que meses sem transação tenham valor 0

  const maiorValor = Math.max(...valores);
  const tickMaximo = maiorValor * 1.1;

  const data = {
    labels: meses,
    datasets: [
      {
        label: "Saldo das Transações (BRL)",
        data: valores,
        backgroundColor: "#36A2EB",
        borderColor: "#36A2EB",
        borderWidth: 1
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Permite customizar a altura do gráfico
    scales: {
      y: {
        beginAtZero: true,
        max: tickMaximo, // Define o tick máximo como maior valor + 10%
        ticks: {
          callback: function (tickValue: string | number) {
            // Formatar os valores no eixo Y como moeda BRL
            return Number(tickValue).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            });
          }
        },
        grid: {
          display: true // Remove as linhas do grid no eixo Y
        }
      },
      x: {
        grid: {
          display: false // Remove as linhas do grid no eixo X
        }
      }
    },
    plugins: {
      legend: {
        display: false // Remover legenda para o gráfico de barras
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const value = tooltipItem.raw;
            // Formatar os valores do tooltip como moeda BRL
            return value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            });
          }
        }
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: "end", // Isso garante que o rótulo esteja no topo da barra
        offset: -1, // Adiciona um espaço entre o rótulo e a barra (ajuste conforme necessário)
        formatter: function (value: number) {
          // Formatar os rótulos em cima das barras como moeda BRL
          return value.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL"
          });
        },
        color: "#666e7a", // Cor dos labels (pode ajustar conforme o background)
        font: {
          weight: "bold",
          size: 12
        }
      }
    }
  };

  const possuiDados = Object.keys(transacoesPorMes).length !== 0;

  return (
    <div className={styles.container}>
      <Quadro titulo="Histórico de movimentações" rota="/movimentacoes">
        {possuiDados ? (
          <div className={styles.wrapper}>
            <div className={styles.grafico} style={{ width: "100%", height: "280px" }}>
              <Bar data={data} options={options as any} />
            </div>
          </div>
        ) : (
          <div className={styles.semDados}>
            <p>Não há dados para exibir.</p>
          </div>
        )}
      </Quadro>
    </div>
  );
}

